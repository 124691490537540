import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft';
import classNames from 'classnames';

import styles from './BottomSheet.module.scss';

import { SlideoutCloseButton } from '../Slideout';

export const BottomSheetTestIds = {
  container: 'bottom-sheet-container',
  backButton: 'bottom-sheet-back-button',
  closeButton: 'bottom-sheet-close-button'
};

export type BottomSheetProps = {
  onClose?: () => void;
  onBack?: () => void;
  title?: ReactNode;
  className?: string;
  canClose?: boolean;
} & PropsWithChildren;

export const BottomSheet: FC<BottomSheetProps> = ({ onClose, children, title, onBack, className, canClose = true }) => {
  const [isClosing, setIsClosing] = useState(false);

  const onCloseClick = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose?.();
    }, 300);
  };

  return (
    <div
      data-testid={BottomSheetTestIds.container}
      className={classNames(styles.bottomSheet, className, { [styles.bottomSheetClosing]: isClosing })}
    >
      {(onBack || canClose) && (
        <div className='d-flex justify-content-between mt-4'>
          <div>
            {onBack && (
              <div data-testid={BottomSheetTestIds.backButton} role='button' onClick={onBack}>
                <FaArrowLeft size={18} />
              </div>
            )}
          </div>
          <div>
            {canClose && <SlideoutCloseButton testID={BottomSheetTestIds.closeButton} onClick={onCloseClick} />}
          </div>
        </div>
      )}
      {title}
      {children}
    </div>
  );
};
