import { useEffect } from 'react';

import { LookingForWatchSlideout } from 'components/purchaseRequests/Slideouts/LookingForWatchSlideout';
import { REQUEST_REMINDER_TIMESTAMP } from 'consts/storageKeys';
import { useGetTreatmentState } from 'context/featureToggle';
import { PURCHASE_REQUESTS_ANNOUNCEMENT } from 'context/featureToggle/consts';
import { useModal } from 'context/modal/ModalContext';
import { getFromStorage, removeFromStorage } from 'lib/storageHelpers';
import { User } from 'types';

export const useShowRequestOnboarding = (user?: User) => {
  const { closeModal, openModal } = useModal();
  const { isEnabled, isLoading } = useGetTreatmentState(PURCHASE_REQUESTS_ANNOUNCEMENT);

  useEffect(() => {
    if (!user || isLoading || !isEnabled) {
      return;
    }
    const reminderDate = getFromStorage(REQUEST_REMINDER_TIMESTAMP, 'localStorage');

    if (reminderDate === 'never') {
      return;
    }

    const now = Date.now();

    if (now > Number(reminderDate)) {
      removeFromStorage(REQUEST_REMINDER_TIMESTAMP, 'localStorage');
      openModal(<LookingForWatchSlideout closeModal={closeModal} />);
    }
  }, [closeModal, isEnabled, isLoading, openModal, user]);
};
