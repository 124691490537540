import { FC } from 'react';
import { useRouter } from 'next/router';

import { BottomSheet } from 'components/design-system/BottomSheet';
import { Button } from 'components/design-system/Button';
import { pageRoutes } from 'lib/routes';
import { PurchaseRequestOffer } from 'types';

import { Countdown } from './Countdown';

export type CheckoutBottomSheetProps = {
  requestOffer: PurchaseRequestOffer;
  onClose: () => void;
};

export const CheckoutBottomSheet: FC<CheckoutBottomSheetProps> = ({ requestOffer, onClose }) => {
  const { push } = useRouter();

  const onCheckoutWatch = () => {
    onClose();
    push(pageRoutes.listingCheckout(requestOffer.listing.id));
  };

  const isExpired = Date.now() > new Date(requestOffer.expiration).getTime();

  return (
    <BottomSheet className='pb-6' canClose={false}>
      <Button
        textClassname='w-100 riforma-medium px-3 d-flex justify-content-between'
        className='w-100 py-3 border-radius-24'
        onClick={onCheckoutWatch}
      >
        <span className={isExpired ? 'text-center w-100' : ''}>Checkout</span>
        {!isExpired && <Countdown expiration={requestOffer.expiration} className='opacity-60' />}
      </Button>
    </BottomSheet>
  );
};
