import { FC } from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { buildListingUrl } from 'lib/url_helpers';
import styles from 'styles/PurchaseRequest.module.scss';
import { Listing, ListingImageType } from 'types';
import { formatPrice } from 'utils/text-helpers';

type ListingItemProps = {
  listing: Listing;
};
export const ListingItem: FC<ListingItemProps> = ({ listing }) => {
  const url = buildListingUrl(
    listing.model.brand.displayName,
    listing.model.displayName,
    listing.model.referenceNumber,
    listing.id
  );
  const imageUrl =
    listing.images.find(i => i.type === ListingImageType.FRONT)?.image.optimizedUrl || 'model_placeholder.png';

  let accessories = '';

  if (listing.accessories.length > 0) {
    accessories += 'with';

    listing.accessories.forEach((accessory, index) => (accessories += `${index > 0 ? ',' : ''} ${accessory.name}`));
  } else if (listing.manufactureYear) {
    accessories += 'with no additional accessories';
  } else {
    accessories += 'No additional accessories';
  }

  return (
    <a
      href={url}
      target='_blank'
      role='button'
      className={classNames(
        'd-flex mt-1 mb-2 w-100 justify-content-between p-1 rounded-2 border border-primary12 text-primary',
        styles.selectionItem
      )}
      style={{ height: 152 }}
      key={listing.id}
    >
      <div className='d-flex h-100 w-60 flex-column justify-content-between ps-3 py-3'>
        <div>
          <div className='fs-14px'>{capitalize(listing.condition.toLowerCase())}</div>
          <div className='riforma-regular opacity-60 fs-12px clamp-lines-4 pe-1'>
            {listing.manufactureYear} {accessories}
          </div>
        </div>
        <div className='text-success fs-14px'>{formatPrice(listing.priceCents)}</div>
      </div>
      <div className='w-40 h-100 position-relative border-radius-16 overflow-hidden'>
        <CloudinaryImage
          fill
          style={{ objectFit: 'cover' }}
          src={imageUrl}
          alt={`${listing.model.displayName} image`}
        />
      </div>
    </a>
  );
};
