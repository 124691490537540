import { FC, useState } from 'react';

import { SlideoutCommonProps } from 'components/design-system/Slideout';
import { toaster } from 'components/design-system/Toaster';
import { clientApiPost } from 'lib/api_helper';
import { PurchaseRequest } from 'types';

import { PurchaseRequestSlideout } from './PurchaseRequestSlideout';

type CloseRequestSlideoutProps = {
  request: PurchaseRequest;
  refreshRequests?: () => void;
} & SlideoutCommonProps;

export const CloseRequestSlideout: FC<CloseRequestSlideoutProps> = ({ request, refreshRequests, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onCloseSlideout = async () => {
    try {
      setIsLoading(true);
      await clientApiPost(`/api/purchaseRequests/close/${request.id}`, {});

      toaster.info('Purchase request closed');
      props.closeModal();
      refreshRequests?.();
    } catch (error) {
      console.error(error);
      toaster.error('Closing purchase request failed. Please try again');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PurchaseRequestSlideout
      modelFromProps={request.model}
      title='Close purchase request'
      submit={{
        loading: isLoading,
        disabled: isLoading,
        title: 'Close purchase request',
        onClick: onCloseSlideout
      }}
      {...props}
    >
      <div>
        <p className='opacity-60 riforma-regular px-3 mt-6 text-center'>
          Are you sure you want to close your purchase request? All seller offers will be automatically declined
        </p>
      </div>
    </PurchaseRequestSlideout>
  );
};
