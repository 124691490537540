import { FC } from 'react';

import { Text } from '../Text';

type SlideoutTitleProps = {
  isDark?: boolean;
  title?: string | React.ReactElement | boolean;
};
export const SlideoutTitle: FC<SlideoutTitleProps> = ({ isDark, title }) => (
  <Text color={isDark ? 'light' : 'primary'} className='w-100 fs-20px px-4 text-center opacity-90 riforma-medium'>
    {title}
  </Text>
);
