import { FC } from 'react';

import { SlideoutCommonProps, SubmitButtonWithArrow } from 'components/design-system/Slideout';
import { SelectionLabel } from 'components/seller/newListing/SelectionLabel';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { getModelProductionYears } from 'utils/getModelProductionYears';

import { PurchaseRequestSlideout } from './PurchaseRequestSlideout';
import { RequestPriceSlideout } from './RequestPriceSlideout';

export const RequestYearSlideout: FC<SlideoutCommonProps> = ({ ...props }) => {
  const { closeModal, openModal } = useModal();
  const { model, year: selectedYear, setYear } = usePurchaseRequest();

  const yearsRange = getModelProductionYears(model).reverse();

  const onYearSubmit = () => openModal(<RequestPriceSlideout closeModal={closeModal} />, true);

  return (
    <PurchaseRequestSlideout
      title='Are you open to all years?'
      subtitle='Let us know if you’re specific about the year of the watch'
      submit={{
        className: 'mt-2',
        textClassname: 'w-100',
        title: (
          <SubmitButtonWithArrow
            text={typeof selectedYear === 'number' ? 'Continue with selected year' : 'Open to all years'}
          />
        ),
        onClick: onYearSubmit
      }}
      {...props}
    >
      <div className='d-flex flex-column w-100 align-items-center'>
        {yearsRange.map(year => (
          <SelectionLabel
            className='my-1 w-100'
            title={year}
            key={year}
            isSelected={year === selectedYear}
            onClick={() => setYear(prev => (prev === year ? undefined : year))}
          />
        ))}
      </div>
    </PurchaseRequestSlideout>
  );
};
