import { FC } from 'react';
import classNames from 'classnames';

import styles from './pill.module.scss';

type PillProps = {
  onClick?: () => void;
  title: string;
  className?: string;
  color?: 'green';
};

const colorSchema = {
  green: 'linear-gradient(269deg, #07745B 0%, #076D74 100%)'
} as const;

export const Pill: FC<PillProps> = ({ onClick, title, className, color = 'green' }) => (
  <div
    role={onClick ? 'button' : undefined}
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();
      onClick?.();
    }}
    className={classNames(
      styles.pill,
      'px-3 py-2 riforma-regular fs-12px text-white border-radius-46',
      { 'pointer-events-none': !onClick },
      className
    )}
    style={{
      background: colorSchema[color]
    }}
  >
    {title}
  </div>
);
