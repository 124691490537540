import { FC } from 'react';

import { SlideoutCommonProps, SubmitButtonWithArrow } from 'components/design-system/Slideout';
import { useModal } from 'context/modal/ModalContext';
import { Listing } from 'types';

import { ListingItem } from './components/ListingItem';
import { PurchaseRequestSlideout } from './PurchaseRequestSlideout';
import { RequestHoldSlideout } from './RequestHoldSlideout';

type MatchingListingsSlideoutProps = { listings: Listing[] } & SlideoutCommonProps;

export const MatchingListingsSlideout: FC<MatchingListingsSlideoutProps> = ({ listings, ...props }) => {
  const { closeModal, openModal } = useModal();

  const onSubmit = () => openModal(<RequestHoldSlideout closeModal={closeModal} />, true);

  return (
    <PurchaseRequestSlideout
      title='Have you seen these examples yet?'
      subtitle='We found an example that matches your requirements already listed '
      submit={{
        className: 'mt-2',
        textClassname: 'w-100',
        title: <SubmitButtonWithArrow text='Continue with request' />,
        onClick: onSubmit
      }}
      {...props}
    >
      <div className='d-flex flex-column w-100 mb-3 align-items-center'>
        {listings.map(listing => (
          <ListingItem listing={listing} key={listing.id} />
        ))}
      </div>
    </PurchaseRequestSlideout>
  );
};
