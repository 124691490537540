import { FC } from 'react';
import { MdOutlineKeyboardArrowRight } from '@react-icons/all-files/md/MdOutlineKeyboardArrowRight';
import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { buildListingUrl } from 'lib/url_helpers';
import { ListingImageType, PurchaseRequestOffer } from 'types';
import { formatPrice } from 'utils/text-helpers';

import { InfoTag } from './InfoTag';

type RequestOfferProps = {
  offer: PurchaseRequestOffer;
  isBuyer: boolean;
  index: number;
};

export const RequestOffer: FC<RequestOfferProps> = ({ offer, isBuyer, index }) => {
  const imageUrl =
    offer.listing?.images.find(i => i.type === ListingImageType.FRONT)?.image.optimizedUrl || 'model_placeholder.png';
  const listingUrl = buildListingUrl(
    offer.listing.model.brand.displayName,
    offer.listing.model.displayName,
    offer.listing.model.referenceNumber,
    offer.listing.id
  );

  const pinkTreatment = offer.status === 'EXPIRED' || offer.status === 'REJECTED';

  return (
    <Link
      role='button'
      href={`${listingUrl}?requestOffer=${offer.id}`}
      target='_blank'
      key={offer.id}
      className='my-2 w-100'
    >
      <div className={classNames('opacity-60 fs-12px riforma-regular', { 'text-end': !isBuyer })}>
        {isBuyer ? `Seller offer ${index === 1 ? '' : '#' + index}` : 'Your offer'}
      </div>
      <div
        className={classNames(
          'd-flex w-100 align-items-center',
          isBuyer ? 'justify-content-between' : 'justify-content-end'
        )}
      >
        <div
          className={classNames('border-radius-24 p-2 position-relative', {
            'bg-primary text-light': !isBuyer,
            'border-success border': offer.status === 'ACCEPTED'
          })}
          style={{ boxShadow: '0px 1px 24px 0px rgba(0, 0, 0, 0.06)' }}
        >
          <div className='border-radius-24 overflow-hidden'>
            <CloudinaryImage width={256} height={256} src={imageUrl} alt={offer.listing.model.displayName} />
          </div>
          <div style={{ maxWidth: 256 }} className='riforma-regular fs-14px opacity-90 mt-1'>
            {offer.listing.manufactureYear ? `${offer.listing.manufactureYear} ` : ''}
            {offer.listing.model.displayName}
          </div>
          <div className='fs-10px opacity-40 letter-spacing-067'>{offer.listing.condition}</div>
          <div
            className={classNames(
              'd-flex align-items-center mt-2 letter-spacing-067 justify-content-between border-radius-16 py-2 px-3',
              {
                'text-light bg-light5': !isBuyer,
                'text-success bg-success04': isBuyer && !pinkTreatment,
                'bg-danger04 text-danger': isBuyer && pinkTreatment
              }
            )}
          >
            <div className='fs-10px'>OFFER</div>
            <div className='d-flex fs-14px'>
              <span>{formatPrice(offer.offerPriceCents)}</span>
              {offer.strikethroughPriceCents !== offer.offerPriceCents && (
                <span className='ms-1 text-decoration-line-through opacity-60'>
                  {formatPrice(offer.strikethroughPriceCents)}
                </span>
              )}
            </div>
          </div>
          <InfoTag offer={offer} />
        </div>
        {isBuyer && (
          <div
            className='bg-primary06 d-flex align-items-center justify-content-center rounded-50'
            style={{ height: 32, width: 32, boxShadow: '0px 1px 24px 0px rgba(0, 0, 0, 0.06)' }}
          >
            <MdOutlineKeyboardArrowRight size={24} />
          </div>
        )}
      </div>
    </Link>
  );
};
