import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { PurchaseRequestOffer } from 'types';

import { Countdown } from './Countdown';

type InfoTagProps = {
  offer: PurchaseRequestOffer;
};

export const InfoTag: FC<InfoTagProps> = ({ offer }) => {
  let content: ReactNode;
  const isEnded = offer.status === 'REJECTED' || offer.status === 'EXPIRED';

  if (offer.status === 'PENDING_REVIEW') {
    content = 'PENDING';
  } else if (offer.status === 'ACCEPTED' || isEnded) {
    content = offer.status;
  } else {
    content = <Countdown expiration={offer.expiration} />;
  }

  return (
    <div
      style={{ top: 20, right: 20 }}
      className={classNames(
        isEnded ? 'bg-danger-pale' : 'bg-success',
        'position-absolute  px-3 py-1 text-light fs-10px border-radius-36'
      )}
    >
      {content}
    </div>
  );
};
