import { FC } from 'react';
import { FaCheck } from '@react-icons/all-files/fa6/FaCheck';
import { useRouter } from 'next/router';

import { SlideoutCommonProps, SlideoutTitle } from 'components/design-system/Slideout';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';

import { PurchaseRequestSlideout } from './PurchaseRequestSlideout';
import { SelectModelSlideout } from './SelectModelSlideout';

type AddedToWantsConfirmationSlideoutProps = SlideoutCommonProps;

export const AddedToWantsConfirmationSlideout: FC<AddedToWantsConfirmationSlideoutProps> = ({ ...props }) => {
  const { closeModal, openModal } = useModal();
  const { resetRequest, model } = usePurchaseRequest();
  const { push } = useRouter();

  const onMakeAnotherRequest = () => {
    resetRequest();
    openModal(<SelectModelSlideout closeModal={closeModal} />);
  };

  const onOpenWants = () => {
    push('/wants');
    props.closeModal();
  };

  return (
    <PurchaseRequestSlideout
      footerClassName='d-flex flex-column-reverse'
      submit={{
        title: 'View wants',
        onClick: onOpenWants
      }}
      cancel={{ className: 'mt-2', title: 'Submit another', onClick: onMakeAnotherRequest }}
      {...props}
      onClickPrevious={undefined}
    >
      <div className='d-flex flex-column w-100 align-items-center'>
        <div className='d-flex my-4 align-items-center justify-content-center p-3 border-radius-60 bg-green12 text-success'>
          <FaCheck size={16} />
        </div>
        <SlideoutTitle title={`${model?.displayName} added to your wants`} />
        <p className='opacity-60 riforma-regular px-3 mt-4 mb-4 text-center'>
          You’ve added this model to your wants. This will keep you updated on any relevant inventory updates and price
          drops.
        </p>
      </div>
    </PurchaseRequestSlideout>
  );
};
