import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useMemo, useState } from 'react';
import useSWR from 'swr';

import { clientApiGet } from 'lib/api_helper';
import { ListingCondition, ListingStat, Model, PurchaseRequest } from 'types';

type PurchaseRequestContextProps = {
  condition?: ListingCondition;
  setCondition: (val: ListingCondition) => void;
  model?: Model;
  setModel: (val: Model) => void;
  year?: number;
  setYear: Dispatch<SetStateAction<number | undefined>>;
  price?: number;
  purchaseRequests: PurchaseRequest[];
  setPrice: (val: number) => void;
  isRequestsLoading: boolean;
  resetRequest: () => void;
  isUrgencySlideoutVisible: boolean;
  setIsUrgencySlideoutVisible: (val: boolean) => void;
  minAvailablePrice?: number;
};

export const PurchaseRequestContext = createContext<PurchaseRequestContextProps | null>(null);

type PurchaseRequestContextProviderProps = PropsWithChildren;

export const PurchaseRequestContextProvider: React.FC<PurchaseRequestContextProviderProps> = ({ children }) => {
  const [condition, setCondition] = useState<ListingCondition>();
  const [model, setModel] = useState<Model>();
  const [year, setYear] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [isUrgencySlideoutVisible, setIsUrgencySlideoutVisible] = useState(false);

  const { data: purchaseRequests = [], isLoading: isRequestsLoading } = useSWR<PurchaseRequest[]>(
    model ? `/api/purchaseRequests/getRequests?model[]=${model.id}&status[]=LIVE&expand[]=model` : null,
    clientApiGet
  );
  const { data: stats } = useSWR<ListingStat>(
    model?.id ? `/api/listings/stats?modelIds=${model.id}` : null,
    clientApiGet
  );

  const resetRequest = () => {
    setCondition(undefined);
    setYear(undefined);
    setPrice(undefined);
  };

  const providerValue = useMemo(
    () => ({
      condition,
      setCondition,
      model,
      setModel,
      year,
      setYear,
      price,
      setPrice,
      purchaseRequests,
      isRequestsLoading,
      resetRequest,
      isUrgencySlideoutVisible,
      setIsUrgencySlideoutVisible,
      minAvailablePrice: stats?.minPrice
    }),
    [condition, model, year, price, purchaseRequests, isRequestsLoading, isUrgencySlideoutVisible, stats?.minPrice]
  );

  return <PurchaseRequestContext.Provider value={providerValue}>{children}</PurchaseRequestContext.Provider>;
};

export const usePurchaseRequest = () => {
  const purchaseRequest = useContext(PurchaseRequestContext);

  if (!purchaseRequest) {
    throw new Error('PurchaseRequestContext used outside of PurchaseRequestContext.Provider!');
  }

  return purchaseRequest;
};
